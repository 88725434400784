type MetaProps = {
    title: string
    description: string
}

export const MetaHeader: React.VFC<MetaProps> = ({ title, description }) => {
    return (
        <>
            <head>
                <meta charSet="utf-8" />
                {
                    title ? <title>{title} </title> : <title>福岡カフェ日記</title>
                }
                {<meta name="title" content={title + ' | 福岡カフェ日記'} />}
                {<meta name="description" content={description} />}
                <meta property="og:title" content={title} />
                {<meta property="og:description" content={description} />}
                <meta name="viewport" content="width=device-width"></meta>
                <link rel="apple-touch-icon" sizes="180x180" href="/images/shared/favicon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/images/shared/favicon.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/images/shared/favicon.png" />
                <meta property="og:site_name" content="福岡カフェ日記" />
                <meta property="og:locale" content="ja_JP" />
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                <link rel="stylesheet" href="/css/reset.css" />
                <link rel="stylesheet" href="/css/component.css"></link>
                <link rel="stylesheet" href="/css/article.css" />
                <link rel="stylesheet" href="/css/categoryPage.css" />
                <link rel="stylesheet" href="/css/common.css" />
                <link rel="stylesheet" href="/css/frontContent.css" />
                <link rel="stylesheet" href="/css/diagonosis.css" />
                <link rel="stylesheet" href="/css/shop.css" />
                <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C01P6CFPJC"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-C01P6CFPJC');
            `,
                    }}
                />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1922093970011434" crossorigin="anonymous"></script>
            </head>
        </>
    )
}