import { Link } from "remix";
export const HeaderList: React.FC<HeaderList> = ({contentList}) => {
    return (
        <>
            <ul className="itemDistanceEvenly">
                {contentList.map((content) => {
                    return (
                        <li className="headerItemPosition" key={content.link}>
                            <Link to={content.link} className="headerItemPosition">{content.content}</Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}