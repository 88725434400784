import { Link } from "remix";
import '../../public/css/common.css';
import '../../public/css/component.css';
import { contentList } from "../constants/index";
import { Humbeger } from './modules/Humbeger';
import { HeaderList } from './modules/HeaderList';
import { useResponsive } from '../hooks/useResponsive';


export const Header = () => {
    const { isMobile, isDesktop } = useResponsive();
    return (
        <header className="header">
            <Link to="/">
                <img className="logoImage" src="/icons/logo.png" alt="サイトロゴ" width="150" height="61" />
            </Link>
            <div className="headerList wrapper">
                <div className="itemDistanceEvenly headerPosition">
                    {isMobile ? (
                        <div className="humbeger_component">
                            <Humbeger contentList={contentList} />
                        </div>
                    ) : (
                        <div className="headerList_component">
                            <HeaderList contentList={contentList} />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
