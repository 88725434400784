import { Link } from "remix";
import '../../public/css/component.css';
import '../../public/css/common.css';
import { bottomItems } from "../constants";

export const FooterNavigation = () => {
    return (
        <div className="footerContent">
            <ul className="footerContents">
                {bottomItems.map((item, index) => {
                    return (
                        <li className="footerContentElement" key={index}>
                            <Link to={item.url} >
                                <img src={`/icons/${item.path}`} alt={item.name} width="30" height="30" />
                                <p>{item.name}</p>
                            </Link>
                        </li>
                    )
                })}

            </ul>
        </div>
    )
}


export default FooterNavigation;