import { sideItems, hashtags } from '../constants/index';
import { Link } from 'react-router-dom';

export const Sidebar = () => {
    return (
        <div className='sideItems contentSide'>
            <ul>
                {sideItems.map((sideItem) => {
                    return (
                        <li key={sideItem.name}>
                            <Link to={sideItem.url} className="sideItem">
                                <p>{sideItem.name}</p>
                                <div>
                                    <img src={`/icons/${sideItem.path}`} />
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </ul>
            <div className="hashTag">
                <h2>-#ハッシュタグ</h2>
                <ul>
                    {hashtags.map((hashtag) => {
                        return (
                            <li key={hashtag.name}>
                                <Link to={`/hashtags/detail?hashtag_id=${hashtag.id}`}>
                                    <p>{hashtag.name}</p>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

