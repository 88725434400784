export const contentList = [
    { link: '/about', content: '福岡カフェ日記とは' },
    { link: '/category/purpose', content: '目的' },
    { link: '/category/menu', content: 'メニュー' },
    { link: '/category/area', content: 'エリア' },
    { link: '/category/atmospheres', content: 'カテゴリー' },
    { link: '/contact', content: 'お問い合わせ' },
    { link: '/hashtags', content: 'ハッシュタグ' },
    { link: '/cafe', content: 'カフェ一覧' },
]

export const sideItems = [
    { name: '目的', url: '/category/purpose', path: 'side_purpose.svg' },
    { name: 'メニュー', url: '/category/menu', path: 'side_menu.svg' },
    { name: 'エリア', url: '/category/area', path: 'side_area.svg' },
    { name: 'カテゴリー', url: '/category/atmospheres', path: 'side_category.svg' },
    { name: 'お問い合わせ', url: '/contact', path: 'side_contact.svg' },
];

export const bottomItems = [
    { name: '目的', url: '/category/purpose', path: 'btm_purpose.svg' },
    { name: 'メニュー', url: '/category/menu', path: 'btm_menu.svg' },
    { name: 'エリア', url: '/category/area', path: 'btm_area.svg' },
    { name: 'カテゴリー', url: '/category/atmospheres', path: 'btm_category.svg' },
];

export const sliderItemImages = [
    { name: '福岡カフェ日記とは', url: '/about', path: 'about.png' },
    { name: 'コンタクト', url: '/contact', path: 'contact.png' },
    { name: 'エリア', url: '/category/area', path: 'area.png' },
];

export const shopDetailLists = [
    { id: 1, title: '店舗詳細' },
    { id: 2, title: 'おすすめな人' },
    { id: 3, title: '店舗情報' },
    { id: 4, title: 'お店の雰囲気' }
]

export const hashtags = [
    { id: 1, name: '淡色カフェ' },
    { id: 2, name: '福岡の大定番' },
    { id: 3, name: '個性の溢れたカフェ' },
    { id: 4, name: '港町のカフェ' },
    { id: 5, name: 'コーヒーと楽しむ' },
    { id: 6, name: '雰囲気のいいカフェ' },
    { id: 7, name: 'カフェ散歩におすすめ' },
    { id: 8, name: '乙女心を鷲掴み' },
    { id: 9, name: '気になるあの子と' },
    { id: 10, name: 'パリジェンヌの3時' },
    { id: 11, name: '野菜たっぷり' },
    { id: 12, name: '暴れたい夜に' },
    { id: 13, name: '冒険に繰り出そう' },
    { id: 14, name: 'カフェ男子' },
    { id: 15, name: '一歩踏み込んだカフェ' },
    { id: 16, name: '自分探しのお店' }
];



export const PurposeImages = [
    { name: 'デート', url: '/category/purpose/detail?article_id=date', path: 'purposeIndex/date.svg' },
    { name: '友達', url: '/category/purpose/detail?article_id=friend', path: 'purposeIndex/friend.svg' },
    { name: 'おひとり', url: '/category/purpose/detail?article_id=single', path: 'purposeIndex/single.svg' },
    // { name: 'ファミリー', url: '/category/purpose/detail?article_id=family', path: 'purposeIndex/family.svg' },
    { name: 'ランチ', url: '/category/purpose/detail?article_id=lunch', path: 'purposeIndex/lunch.svg' },
    // { name: '大人数', url: '/category/purpose/detail?article_id=many', path: 'purposeIndex/many.svg' },
    { name: 'モーニング', url: '/category/purpose/detail?article_id=morning', path: 'purposeIndex/morning.svg' },
    { name: 'テイクアウト', url: '/category/purpose/detail?article_id=takeout', path: 'purposeIndex/takeout.svg' },
    { name: '初デート', url: '/category/purpose/detail?article_id=first_date', path: 'purposeIndex/first_date.png' },
    { name: '男カフェ', url: '/category/purpose/detail?article_id=mens', path: 'purposeIndex/mens.png' },
    { name: 'インスタ映え', url: '/category/purpose/detail?article_id=Instagenic', path: 'purposeIndex/instagram.png' },
    { name: '本を読みたいカフェ   ', url: '/category/purpose/detail?article_id=book', path: 'purposeIndex/book.png' },
]

export const AreaImages = [
    { name: '呉服町', url: '/category/area/detail?article_id=gofukumachi', path: 'areaIndex/kv/gofukumachi.png' },
    { name: '中洲', url: '/category/area/detail?article_id=nakasu', path: 'areaIndex/kv/nakasu.png' },
    { name: '博多駅', url: '/category/area/detail?article_id=hakataeki', path: 'areaIndex/kv/hakataeki.png' },
    { name: '大濠', url: '/category/area/detail?article_id=ohori', path: 'areaIndex/kv/ohori.png' },
    { name: '警固', url: '/category/area/detail?article_id=kego', path: 'areaIndex/kv/kego.png' },
    { name: '平尾', url: '/category/area/detail?article_id=hirao', path: 'areaIndex/kv/hirao.png' },
    { name: '今泉', url: '/category/area/detail?article_id=imaizumi', path: 'areaIndex/kv/imaizumi.png' },
    { name: '天神', url: '/category/area/detail?article_id=tenjin', path: 'areaIndex/kv/tenjin.png' },
    { name: '赤坂', url: '/category/area/detail?article_id=akasaka', path: 'areaIndex/kv/akasaka.png' },
    { name: '大名', url: '/category/area/detail?article_id=daimyo', path: 'areaIndex/kv/daimyo.png' },
    { name: '春吉', url: '/category/area/detail?article_id=haruyoshi', path: 'areaIndex/kv/haruyoshi.png' },
    // { name: '桜坂', url: '/category/area/detail?article_id=sakurazaka', path: 'areaIndex/kv/sakurazaka.png' },
    { name: '薬院', url: '/category/area/detail?article_id=yakuin', path: 'areaIndex/kv/yakuin.png' },
    { name: '六本松', url: '/category/area/detail?article_id=ropponmatsu', path: 'areaIndex/kv/ropponmatsu.png' },
    // { name: '大手門', url: '/category/area/detail?article_id=otemon', path: 'areaIndex/kv/otemon.png' },
    // { name: '黒門', url: '/category/area/detail?article_id=kuromon', path: 'areaIndex/kv/kuromon.png' },
    { name: '白金', url: '/category/area/detail?article_id=shirogane', path: 'areaIndex/kv/shirogane.png' },
    // { name: '高砂', url: '/category/area/detail?article_id=takasago', path: 'areaIndex/kv/takasago.png' },
    // { name: '鳥飼', url: '/category/area/detail?article_id=torikai', path: 'areaIndex/kv/torikai.png' },
    // { name: '香椎', url: '/category/area/detail?article_id=kashii', path: 'areaIndex/kv/kashii.png' },
    { name: '箱崎', url: '/category/area/detail?article_id=hakozaki', path: 'areaIndex/kv/hakozaki.png' },
    // { name: '大橋', url: '/category/area/detail?article_id=ohashi', path: 'areaIndex/kv/ohashi.png' },
    { name: '北九州市', url: '/category/area/detail?article_id=kitakyushu', path: 'areaIndex/kv/kitakyushu.png' },
    // { name: '西新', url: '/category/area/detail?article_id=nishijin', path: 'areaIndex/kv/nishijin.png' },
    { name: '朝倉', url: '/category/area/detail?article_id=asakura', path: 'areaIndex/kv/asakura.png' },
    { name: '糸島', url: '/category/area/detail?article_id=itoshima', path: 'areaIndex/kv/itoshima.png' },
    { name: 'うきは', url: '/category/area/detail?article_id=ukiha', path: 'areaIndex/kv/ukiha.png' },
    // { name: '大川', url: '/category/area/detail?article_id=okawa', path: 'areaIndex/kv/okawa.png' },
    // { name: '大野城', url: '/category/area/detail?article_id=onojyo', path: 'areaIndex/kv/onojyo.png' },
    // { name: '大牟田', url: '/category/area/detail?article_id=omuta', path: 'areaIndex/kv/omuta.png' },
    // { name: '小郡', url: '/category/area/detail?article_id=ogōri', path: 'areaIndex/kv/ogōri.png' },
    // { name: '遠賀', url: '/category/area/detail?article_id=onga', path: 'areaIndex/kv/onga.png' },
    { name: '久留米', url: '/category/area/detail?article_id=kurume', path: 'areaIndex/kv/kurume.png' },
    { name: '篠栗', url: '/category/area/detail?article_id=sasaguri', path: 'areaIndex/kv/sasaguri.png' },
    // { name: '志免', url: '/category/area/detail?article_id=shime', path: 'areaIndex/kv/shime.png' },
    // { name: '田川', url: '/category/area/detail?article_id=tagawa', path: 'areaIndex/kv/tagawa.png' },
    { name: '太宰府', url: '/category/area/detail?article_id=dazaifu', path: 'areaIndex/kv/dazaifu.png' },
    // { name: '筑後', url: '/category/area/detail?article_id=chikugo', path: 'areaIndex/kv/chikugo.png' },
    { name: '筑紫野', url: '/category/area/detail?article_id=chikushino', path: 'areaIndex/kv/chikushino.png' },
    // { name: '那珂川', url: '/category/area/detail?article_id=nakagawa', path: 'areaIndex/kv/nakagawa.png' },
    { name: '福津', url: '/category/area/detail?article_id=fukutsu', path: 'areaIndex/kv/fukutsu.png' },
    // { name: '宗像', url: '/category/area/detail?article_id=munakata', path: 'areaIndex/kv/munakata.png' },
    { name: '八女', url: '/category/area/detail?article_id=yame', path: 'areaIndex/kv/yame.png' },
    { name: '門司', url: '/category/area/detail?article_id=moji', path: 'areaIndex/kv/moji.png' },
    { name: '小倉北', url: '/category/area/detail?article_id=kokura-kita', path: 'areaIndex/kv/kokura-kita.png' },
    // { name: '小倉南', url: '/category/area/detail?article_id=kokura-minami', path: 'areaIndex/kv/kokura-minami.png' },
    { name: '若松', url: '/category/area/detail?article_id=wakamatsu', path: 'areaIndex/kv/wakamatsu.png' },
    { name: '八幡東', url: '/category/area/detail?article_id=yahata-higashi', path: 'areaIndex/kv/yahata-higashi.png' },
    // { name: '八幡西', url: '/category/area/detail?article_id=yahata-nishi', path: 'areaIndex/kv/yahata-nishi.png' },
    { name: '東区', url: '/category/area/detail?article_id=higashi', path: 'areaIndex/kv/higashi.png' },
    { name: '西区', url: '/category/area/detail?article_id=nishi', path: 'areaIndex/kv/nishi.png' },
    { name: '南区', url: '/category/area/detail?article_id=minami', path: 'areaIndex/kv/minami.png' },
    // { name: '城南区', url: '/category/area/detail?article_id=jonan', path: 'areaIndex/kv/jonanku.png' },
    { name: '早良区', url: '/category/area/detail?article_id=sawara', path: 'areaIndex/kv/sawara.png' },
    { name: '糟屋郡', url: '/category/area/detail?article_id=kasuya', path: 'areaIndex/kv/kasuya.png' }
];

export const MenuImages = [
    { name: 'カヌレ', url: '/category/menu/detail?article_id=canele', path: 'menuIndex/kv/canele.png' },
    { name: 'サンドイッチ', url: '/category/menu/detail?article_id=sandwich', path: 'menuIndex/kv/sandwich.png' },
    { name: 'パンケーキ', url: '/category/menu/detail?article_id=pancake', path: 'menuIndex/kv/pancake.png' },
    { name: 'フレンチトースト', url: '/category/menu/detail?article_id=french_toast', path: 'menuIndex/kv/french_toast.png' },
    { name: 'タルト', url: '/category/menu/detail?article_id=tart', path: 'menuIndex/kv/tart.png' },
    { name: 'アイスクリーム', url: '/category/menu/detail?article_id=ice_cream', path: 'menuIndex/kv/ice_cream.png' },
    { name: 'パフェ', url: '/category/menu/detail?article_id=parfait', path: 'menuIndex/kv/parfait.png' },
    { name: 'クリームソーダ', url: '/category/menu/detail?article_id=cream_soda', path: 'menuIndex/kv/cream_soda.png' },
    { name: 'フルーツサンドイッチ', url: '/category/menu/detail?article_id=fruit_sandwich', path: 'menuIndex/kv/fruit_sandwich.png' },
    { name: 'あんこ', url: '/category/menu/detail?article_id=anko', path: 'menuIndex/kv/anko.png' },
    { name: 'ドーナツ', url: '/category/menu/detail?article_id=donut', path: 'menuIndex/kv/donut.png' },
    { name: '抹茶', url: '/category/menu/detail?article_id=matcha', path: 'menuIndex/kv/matcha.png' },
    { name: 'スコーン', url: '/category/menu/detail?article_id=scone', path: 'menuIndex/kv/scone.png' },
    { name: 'チョコレート', url: '/category/menu/detail?article_id=chocolate', path: 'menuIndex/kv/chocolate.png' },
    { name: 'ティラミス', url: '/category/menu/detail?article_id=tiramisu', path: 'menuIndex/kv/tiramisu.png' },
    { name: 'パスタ', url: '/category/menu/detail?article_id=pasta', path: 'menuIndex/kv/pasta.png' },
    { name: '団子', url: '/category/menu/detail?article_id=dango', path: 'menuIndex/kv/dango.png' },
    { name: 'ピザ', url: '/category/menu/detail?article_id=pizza', path: 'menuIndex/kv/pizza.png' },
    { name: 'クロッフル', url: '/category/menu/detail?article_id=croffle', path: 'menuIndex/kv/croffle.png' },
    { name: 'サラダ', url: '/category/menu/detail?article_id=salad', path: 'menuIndex/kv/salad.png' },
    { name: 'マフィン', url: '/category/menu/detail?article_id=muffin', path: 'menuIndex/kv/muffin.png' },
    { name: 'さつまいも', url: '/category/menu/detail?article_id=sweet_potato', path: 'menuIndex/kv/sweet_potato.png' },
    { name: 'ナポリタン', url: '/category/menu/detail?article_id=neapolitan', path: 'menuIndex/kv/neapolitan.png' },
    { name: 'モンブラン', url: '/category/menu/detail?article_id=mont_blanc', path: 'menuIndex/kv/mont_blanc.png' },
    // { name: 'ジェラート', url: '/category/menu/detail?article_id=gelato', path: 'menuIndex/kv/gelato.png' },
    { name: 'クレープ', url: '/category/menu/detail?article_id=crepe', path: 'menuIndex/kv/crepe.png' },
    { name: '和菓子', url: '/category/menu/detail?article_id=wagashi', path: 'menuIndex/kv/wagashi.png' },
    { name: 'カレー', url: '/category/menu/detail?article_id=curry', path: 'menuIndex/kv/curry.png' },
    { name: 'ホットサンドイッチ', url: '/category/menu/detail?article_id=hot_sandwich', path: 'menuIndex/kv/hot_sandwich.png' },
    { name: 'オムライス', url: '/category/menu/detail?article_id=omelet_rice', path: 'menuIndex/kv/omelet_rice.png' },
    { name: 'チャイ', url: '/category/menu/detail?article_id=chai', path: 'menuIndex/kv/chai.png' },
    { name: 'かき氷', url: '/category/menu/detail?article_id=shaved_ice', path: 'menuIndex/kv/shaved_ice.png' },


];

export const AtmosphereImages = [
    { name: '夜カフェ', url: '/category/atmospheres/detail?article_id=night_cafe', path: 'atmosphereIndex/kv/night_cafe.png' },
    { name: 'ランチカフェ', url: '/category/atmospheres/detail?article_id=lunch_cafe', path: 'atmosphereIndex/kv/lunch_cafe.png' },
    { name: '淡色カフェ', url: '/category/atmospheres/detail?article_id=pale_cafe', path: 'atmosphereIndex/kv/pale_cafe.png' },
    { name: '海の見えるカフェ', url: '/category/atmospheres/detail?article_id=sea_view_cafe', path: 'atmosphereIndex/kv/sea_view_cafe.png' },
    { name: '個性のあるカフェ', url: '/category/atmospheres/detail?article_id=unique_cafe', path: 'atmosphereIndex/kv/unique_cafe.png' },
    { name: '居心地の良いカフェ', url: '/category/atmospheres/detail?article_id=cozy_cafe', path: 'atmosphereIndex/kv/cozy_cafe.png' },
    { name: '古民家カフェ', url: '/category/atmospheres/detail?article_id=old_house_cafe', path: 'atmosphereIndex/kv/old_house_cafe.png' },
    { name: 'ブックカフェ', url: '/category/atmospheres/detail?article_id=book_cafe', path: 'atmosphereIndex/kv/book_cafe.png' },
    { name: '木漏れ日カフェ', url: '/category/atmospheres/detail?article_id=dappled_light_cafe', path: 'atmosphereIndex/kv/dappled_light_cafe.png' },
    { name: 'ギャラリーカフェ', url: '/category/atmospheres/detail?article_id=gallery_cafe', path: 'atmosphereIndex/kv/gallery_cafe.png' },
    { name: 'モノトーンカフェ', url: '/category/atmospheres/detail?article_id=monochrome_cafe', path: 'atmosphereIndex/kv/monochrome_cafe.png' },
    { name: '隠れ家カフェ', url: '/category/atmospheres/detail?article_id=hideaway_cafe', path: 'atmosphereIndex/kv/hideaway_cafe.png' },
    { name: '韓国スタイルカフェ', url: '/category/atmospheres/detail?article_id=korean_style_cafe', path: 'atmosphereIndex/kv/korean_style_cafe.png' },
    { name: 'コーヒースタンド', url: '/category/atmospheres/detail?article_id=coffee_stand', path: 'atmosphereIndex/kv/coffee_stand.png' },
    { name: 'ロースタリーカフェ', url: '/category/atmospheres/detail?article_id=roastery_cafe', path: 'atmosphereIndex/kv/roastery_cafe.png' },
    { name: '雑貨カフェ', url: '/category/atmospheres/detail?article_id=goods_cafe', path: 'atmosphereIndex/kv/goods_cafe.png' },
    { name: 'コーヒーショップ', url: '/category/atmospheres/detail?article_id=coffee_shop', path: 'atmosphereIndex/kv/coffee_shop.png' },
    { name: '推しかつカフェ', url: '/category/atmospheres/detail?article_id=favorite_cafe', path: 'atmosphereIndex/kv/favorite_cafe.png' },
];

export const aboutContents = [
    { title: "福岡カフェをもっと身近に", desc: "福岡には魅力的なお店が多い。シャレなお店、雰囲気の良いお店、カフェの魅力は様々。自分好みのカフェ探しの旅へ、さあ出かけよう。", img: "intro_1.png" },
    { title: "物語のようなSNSマガジン", desc: "福岡のカフェをキュレーションしたメディア、福岡カフェ日記。みなさまの好きが詰まったお店を紹介しています。ページをめくるように覗いていただけると嬉しいです。", img: "intro_2.png" },
    { title: "ばりよか！福岡", desc: "福岡には魅力的なお店がたくさん！どこ行く？どこ寄る？心からワクワクするお店を紹介しています。あなたはこのお店知っとると？", img: "intro_3.png" },
]

export const typeArray = [
    { title: "JAVA", subTitle: "定番のカフェが好きな", desc: "安定した味を求める伝統主義者", img: '/images/diagnosis/java.png' },
    { title: "KENY", subTitle: "人とは違うカフェに行きたい", desc: "革新を求める挑戦者", img: '/images/diagnosis/keny.png' },
    { title: "YIRG", subTitle: "誰も知らないカフェに行きたい", desc: "未知の魅力に引かれる探求者", img: '/images/diagnosis/yirg.png' },
    { title: "BLUE", subTitle: "インスタ映えするお店に行きたい", desc: "シーンを彩るトレンドセッター", img: '/images/diagnosis/suma.png' },
    { title: "SUMA", subTitle: "静かに足を伸ばすお店を探している", desc: "穏やかな環境で心を落ち着かせたい守護者", img: '/images/diagnosis/kona.png' },
    { title: "KONA", subTitle: "おしゃべりができるお店を探している", desc: "コミュニケーションを楽しむ社交家", img: '/images/diagnosis/rob.png' },
    { title: "ROB", subTitle: "コーヒーが美味しいお店を探している", desc: "味を追求するコーヒー純粋主義者", img: '/images/diagnosis/harr.png' },
    { title: "HARR", subTitle: "芸術的なカフェに行きたい", desc: "芸術と感性を愛するクリエイティブソウル", img: '/images/diagnosis/guat.png' },
    { title: "GUAT", subTitle: "美味しい食事がしたい", desc: "美味しいは正義、一口は幸せタイプ", img: '/images/diagnosis/blue.png' }
]

export const questionArray: QuestionArray = [
    {
        question1: "誰も行ったことないような隠れ家的お店が好き。",
        ans1: [-3, 5, 5, 0, 3, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [2, -4, -5, 0, -2, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [-2, 3, 4, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [1, -2, -3, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question2: "アートやクリエイティブな雰囲気が好きだ。",
        ans1: [0, 2, 0, -2, 4, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, -4, 0, 2, 0, 0, 0, -4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 1, 0, -1, 2, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, -2, 0, 1, 0, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question3: "お店を選ぶ時は雰囲気よりも美味しい食事が楽しめるかの方が重要だ",
        ans1: [0, 0, 0, 0, -2, -1, 0, -2, 10, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, 0, 0, 0, 2, 1, 0, 2, -4, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 0, 0, 0, -1, 0, 0, -1, 5, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, 0, 0, 0, 1, 0, 0, 1, -2, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question4: "新作のメニュー・期間限定のメニューがあると試したくなる",
        ans1: [5, 2, 2, 3, -3, 0, -1, 2, 4, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [-2, -2, -2, 0, 0, 0, 1, -2, -2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [2, 1, 1, 2, -1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [-1, -1, -1, 0, 0, 0, 0, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question5: "福岡のカフェに関するランキングや人気投稿を頻繁にチェックしている",
        ans1: [4, 0, -1, 4, 0, 1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [-4, 0, 2, -4, 0, -1, 1, -2, -1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [2, 0, 0, 2, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [-2, 0, 1, -2, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question6: "コーヒーを選ぶとき、産地や焙煎の深さなど自分だけのこだわりがある",
        ans1: [0, 4, 0, 0, 0, 0, 20, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, 0, 0, 0, 0, 0, 0, -2, -2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 2, 0, 0, 0, 0, 10, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, 0, 0, 0, 0, 0, 0, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question7: "リラックスして本を読めるような落ち着いた雰囲気の空間が好きだ",
        ans1: [0, 3, 0, -4, 6, -4, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, 0, 0, 4, -4, 4, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 1, 0, -2, 4, -2, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, 0, 0, 2, -2, 2, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question8: "インスタグラムやSNSで人気のお店によくいく",
        ans1: [6, -4, -4, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [-2, 4, 4, -2, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [3, -2, -2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [-1, 2, 2, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question9: "カフェに行く時は友達とおしゃべりするときに行く",
        ans1: [3, 0, 0, 3, 2, 8, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, 0, 0, -2, -2, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [1, 0, 0, 1, 1, 4, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, 0, 0, -1, -1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question10: "お店の外観や建物も含めて全体のデザインが気に入っているお店に足を運ぶことが多い",
        ans1: [0, 4, 2, 2, 4, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, -2, -2, -2, 0, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 2, 1, 1, 2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, -1, -1, -1, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question11: "人の行列や人気度よりも、自分の直感や興味を優先してお店を選ぶことが多い",
        ans1: [0, 4, 4, 2, 2, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, -2, -2, -2, 0, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 3, 3, 1, 2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, -1, -1, -1, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        question12: "友達や知り合いが行っているお店に行ってみたいと思う。",
        ans1: [5, -3, 0, 2, 2, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [-2, 2, 0, -2, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [3, -2, 0, 1, 1, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [-1, 1, 0, -1, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    ,
    {
        question13: "お気に入りのお店を見つけると頻繁に通うほうだ",
        ans1: [0, 4, 4, 0, 4, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, -2, -2, 0, 0, -2, -2, -2, -2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 3, 3, 0, 2, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, -1, -1, 0, 0, -1, -1, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    ,
    {
        question14: "写真を撮る時、背景や雰囲気にもこだわりたいと思う",
        ans1: [0, 2, 2, 6, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, -2, -2, 0, 0, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 1, 1, 3, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, -1, -1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    ,
    {
        question15: "楽しい会話や笑い声が、良い時間を過ごすための重要な要素だ",
        ans1: [0, 0, 0, 0, 2, 2, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [0, 0, 0, 0, 0, -2, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [0, 0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [0, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    ,
    {
        question16: "福岡のカフェといえば！のような王道なカフェに行くことが多い",
        ans1: [4, 0, -2, 4, 0, 0, -2, -4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans2: [-4, 0, 1, -2, 0, 0, 1, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans3: [3, 0, -1, 3, 0, 0, -1, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ans4: [2, 0, 0, -1, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
];

export const topContent = [
    {
        title_main: "行く相手から選択",
        title: "目的",
        desc: "モーニングやランチなどのおすすめなお店をご紹介！目的に合わせたカフェ探しの参考にしてくださいね！",
        url: "category/purpose",
        img: "purpose_icon.svg",
        images: [
            {
                img_title: "デート",
                url: "/category/purpose/detail?article_id=date",
                img: "purpose_date.svg",
            },
            {
                img_title: "友達",
                url: "/category/purpose/detail?article_id=friend",
                img: "purpose_friend.svg",
            },
            {
                img_title: "おひとり",
                url: "/category/purpose/detail?article_id=single",
                img: "purpose_single.svg",
            },
            {
                img_title: "インスタ映え",
                url: "/category/purpose/detail?article_id=Instagenic",
                img: "purpose_morning.svg",
            }
        ]
    },
    {
        title_main: "人気メニューから選択",
        title: "メニュー",
        desc: "カフェメニューの中でも人気なスイーツを厳選してご紹介。美味しいスイーツ探しの参考にしてみてくださいね！",
        url: "category/menu",
        img: "menu_icon.svg",
        images: [
            {
                img_title: "プリン",
                url: "/category/menu/detail?article_id=purin",
                img: "menu_purin.jpg",
            },
            {
                img_title: "ドーナツ",
                url: "/category/menu/detail?article_id=donut",
                img: "menu_donut.jpg",
            },
            {
                img_title: "チーズケーキ",
                url: "/category/menu/detail?article_id=cheesecake",
                img: "menu_cheesecake.jpg",
            },
            {
                img_title: "抹茶スイーツ",
                url: "/category/menu/detail?article_id=matcha",
                img: "menu_maccha.jpg",
            }
        ]
    },
    {
        title_main: "人気エリアから選択",
        title: "エリア",
        desc: "福岡のカフェを地域ごとに紹介しています。自分好みのカフェを探す旅へ、さぁ出かけよう！",
        url: "category/area",
        img: "area_icon.svg",
        images: [
            {
                img_title: "博多",
                url: "/category/area/detail?article_id=hakataeki",
                img: "hakataeki.png",
            },
            {
                img_title: "門司",
                url: "/category/area/detail?article_id=moji",
                img: "moji.png",
            },
            {
                img_title: "天神",
                url: "/category/area/detail?article_id=tenjin",
                img: "tenjin.png",
            },
            {
                img_title: "糸島",
                url: "/category/area/detail?article_id=itoshima",
                img: "itoshima.png",
            }
        ]
    },
    {
        title_main: "行く相手から選択",
        title: "カテゴリー",
        desc: "海カフェや夕陽の見えるカフェなど自然と楽しむカフェをご紹介。季節や気分に合わせたカフェ探しの参考にしてみてください。",
        url: "category/atmospheres",
        img: "category_icon.svg",
        img_article: "人気エリア",
        images: [
            {
                img_title: "古民家",
                url: "/category/atmospheres/detail?article_id=old_house_cafe",
                img: "category_kominka.jpg",
            },
            {
                img_title: "木漏れ日",
                url: "/category/atmospheres/detail?article_id=dappled_light_cafe",
                img: "category_komore.jpg",
            },
            {
                img_title: "ブックカフェ",
                url: "/category/atmospheres/detail?article_id=book_cafe",
                img: "category_book.jpg",
            },
            {
                img_title: "韓国っぽ",
                url: "/category/atmospheres/detail?article_id=korean_style_cafe",
                img: "category_corea.jpg",
            }
        ]
    }
]