import '../../public/css/component.css';
import '../../public/css/common.css';

export const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <p>©︎福岡カフェ日記</p>
                <p>プライバシーポリシーについて</p>
            </div>
        </footer>
    )
}

export default Footer;
