import { Link } from "remix";
import { useState } from "react";
export const Humbeger:React.FC<HeaderList> = ({contentList}) => {
    const [openMenu, setOpenMenu] = useState(false)
    const handleUp = () =>{
        setOpenMenu(!openMenu)
    }
    return(
        <>
            <nav id="nav">
                <div className="hamburger" onClick={handleUp}>
                    <span className={openMenu ? "line line01 v-line01": "line line01"}></span>
                    <span className={openMenu ? "line line02 v-line02" : "line line02" }></span>
                    <span className={openMenu ? "line line03 v-line03" : "line line03" }></span>
                </div>
                {openMenu && <div className="menu">
                    <ul>
                    {contentList.map((content) => {
                            return (
                                <li key={content.link}>
                                    <Link to={content.link}>{content.content}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>}
            </nav>
        </>
    )
}